const content = document.querySelector('[data-cy="content"]')

// Add margin to the top of the content when the banner is visible
window.addEventListener('banner:visible', () => {
  content.classList.add('mt-banner')
})

// Remove margin from the top of the content when the banner is dismissed
window.addEventListener('banner:dismissed', () => {
  content.classList.remove('mt-banner')
})
